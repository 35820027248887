export const CurateContentSVGDesktop = () => <svg width="1417" height="161" viewBox="0 0 1417 161" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.7378 56.7151H130.877V102.692H38.2378C36.3048 102.692 34.7378 101.125 34.7378 99.1924V56.7151Z" stroke="#E4EDFF" />
    <path d="M207 56.5114L131.877 56.5113L131.877 4.47927C131.877 2.54628 133.444 0.979276 135.377 0.979276L207 0.979282C208.933 0.979282 210.5 2.54628 210.5 4.47928L210.5 53.0114C210.5 54.9444 208.933 56.5114 207 56.5114Z" stroke="#E4EDFF" />
    <path d="M0 26.7736C0 24.5644 1.79086 22.7736 4 22.7736H30.2377C32.4469 22.7736 34.2377 24.5644 34.2377 26.7736V57.0113H3.99999C1.79086 57.0113 0 55.2204 0 53.0113V26.7736Z" fill="#ACC9FF" />
    <path d="M130.581 103.193H178.074C179.261 103.193 180.387 103.72 181.147 104.632L186.982 111.635C187.581 112.354 187.909 113.26 187.909 114.195V160.521H141.584C140.648 160.521 139.742 160.193 139.023 159.594L132.02 153.758C131.108 152.998 130.581 151.872 130.581 150.685V103.193Z" fill="#E4EDFF" />
    <path d="M130.581 103.193H177.54C179.749 103.193 181.54 104.983 181.54 107.193V154.151H134.581C132.372 154.151 130.581 152.36 130.581 150.151V103.193Z" fill="#FFE2B0" />
    <path d="M152.357 126.445H141.103C140.198 126.445 139.463 125.715 139.463 124.816V113.641C139.463 112.742 140.198 112.012 141.103 112.012H152.357C153.262 112.012 153.997 112.742 153.997 113.641V124.816C153.997 125.715 153.262 126.445 152.357 126.445Z" fill="#FF9051" stroke="#FF9051" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M152.357 145.633H141.103C140.198 145.633 139.463 144.904 139.463 144.005V132.83C139.463 131.931 140.198 131.201 141.103 131.201H152.357C153.262 131.201 153.997 131.931 153.997 132.83V144.005C153.997 144.904 153.262 145.633 152.357 145.633Z" stroke="#FF9051" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M171.735 126.445H160.481C159.576 126.445 158.841 125.715 158.841 124.816V113.641C158.841 112.742 159.576 112.012 160.481 112.012H171.735C172.641 112.012 173.375 112.742 173.375 113.641V124.816C173.375 125.715 172.641 126.445 171.735 126.445Z" stroke="#FF9051" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M171.735 145.633H160.481C159.576 145.633 158.841 144.904 158.841 144.005V132.83C158.841 131.931 159.576 131.201 160.481 131.201H171.735C172.641 131.201 173.375 131.931 173.375 132.83V144.005C173.375 144.904 172.641 145.633 171.735 145.633Z" stroke="#FF9051" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M1218 35.1615H1306.37C1308.3 35.1615 1309.87 36.7285 1309.87 38.6615V96.2218C1309.87 98.1548 1308.3 99.7218 1306.37 99.7218H1218C1216.07 99.7218 1214.5 98.1548 1214.5 96.2218V38.6615C1214.5 36.7285 1216.07 35.1615 1218 35.1615Z" stroke="#E4EDFF" />
    <path d="M1349.07 4.69654C1349.07 2.4874 1350.86 0.696533 1353.07 0.696533L1413 0.696533C1415.21 0.696533 1417 2.48739 1417 4.69653V30.6615C1417 32.8707 1415.21 34.6615 1413 34.6615L1353.07 34.6615C1350.86 34.6615 1349.07 32.8707 1349.07 30.6615V4.69654Z" fill="#FFE2B0" />
    <path d="M1309.58 99.4319H1356.68C1357.86 99.4319 1358.99 99.9592 1359.75 100.871L1365.52 107.798C1366.12 108.517 1366.45 109.423 1366.45 110.359V156.303H1320.5C1319.57 156.303 1318.66 155.975 1317.94 155.376L1311.02 149.604C1310.1 148.844 1309.58 147.718 1309.58 146.531V99.4319Z" fill="#D2E2FF" />
    <path d="M1309.58 99.4319H1356.13C1358.34 99.4319 1360.13 101.223 1360.13 103.432V149.984H1313.58C1311.37 149.984 1309.58 148.194 1309.58 145.984V99.4319Z" fill="#5892FF" />
    <ellipse cx="1346.3" cy="118.919" rx="3.97703" ry="4.00504" transform="rotate(90 1346.3 118.919)" stroke="#183D82" strokeWidth="2" />
    <path d="M1346.3 108.977L1346.3 114.942" stroke="#183D82" strokeWidth="2" strokeLinejoin="round" />
    <path d="M1346.3 122.896L1346.3 140.793" stroke="#183D82" strokeWidth="2" strokeLinejoin="round" />
    <ellipse cx="1322.27" cy="118.919" rx="3.97703" ry="4.00504" transform="rotate(90 1322.27 118.919)" stroke="#183D82" strokeWidth="2" />
    <path d="M1322.27 108.977L1322.27 114.942" stroke="#183D82" strokeWidth="2" strokeLinejoin="round" />
    <path d="M1322.27 122.896L1322.27 140.793" stroke="#183D82" strokeWidth="2" strokeLinejoin="round" />
    <ellipse cx="1334.28" cy="130.851" rx="3.97703" ry="4.00504" transform="rotate(90 1334.28 130.851)" stroke="#183D82" strokeWidth="2" />
    <path d="M1334.28 108.977L1334.28 126.873" stroke="#183D82" strokeWidth="2" strokeLinejoin="round" />
    <path d="M1334.28 134.828L1334.28 140.793" stroke="#183D82" strokeWidth="2" strokeLinejoin="round" />
</svg>

