export const TrackContentSVGDesktop = () => <svg width="1414" height="258" viewBox="0 0 1414 258" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1236.4 40.5H1282C1283.93 40.5 1285.5 42.067 1285.5 44V93.1H1236.4C1234.47 93.1 1232.9 91.533 1232.9 89.6V44C1232.9 42.067 1234.47 40.5 1236.4 40.5Z" stroke="#ACC9FF" />
    <path d="M1286.5 94.8999H1410C1411.93 94.8999 1413.5 96.4669 1413.5 98.3999V180C1413.5 181.933 1411.93 183.5 1410 183.5H1286.5V94.8999Z" stroke="#E4EDFF" />
    <path d="M1170 152C1170 149.791 1171.79 148 1174 148H1202C1204.21 148 1206 149.791 1206 152V180C1206 182.209 1204.21 184 1202 184H1174C1171.79 184 1170 182.209 1170 180V152Z" fill="#E4EDFF" />
    <path d="M233.563 53.0315H253C255.209 53.0315 257 54.8224 257 57.0315V72.4686C257 74.6777 255.209 76.4686 253 76.4686H237.563C235.354 76.4686 233.563 74.6777 233.563 72.4686V53.0315Z" fill="#FFE2B0" />
    <path d="M233.063 52.5315L187.456 52.5315C185.523 52.5315 183.956 50.9645 183.956 49.0315L183.956 4.50005C183.956 2.56705 185.523 1.00005 187.456 1.00005L229.563 1.00005C231.496 1.00005 233.063 2.56706 233.063 4.50005L233.063 52.5315Z" stroke="#E4EDFF" />
    <path d="M4 161.827H129.616V253.5C129.616 255.433 128.049 257 126.116 257H4C2.06701 257 0.5 255.433 0.5 253.5V165.327C0.5 163.394 2.067 161.827 4 161.827Z" stroke="#E4EDFF" />
    <path d="M134.925 118.994H236.028C237.961 118.994 239.528 120.561 239.528 122.494V157.327C239.528 159.26 237.961 160.827 236.028 160.827H131.425V122.494C131.425 120.561 132.992 118.994 134.925 118.994Z" stroke="#E4EDFF" />
    <path d="M73.544 59.4968H121.777C122.965 59.4968 124.09 60.0241 124.85 60.9361L130.806 68.0824C131.405 68.8013 131.733 69.7074 131.733 70.6431V117.686H84.6903C83.7546 117.686 82.8484 117.357 82.1296 116.758L74.9833 110.803C74.0713 110.043 73.544 108.917 73.544 107.73V59.4968Z" fill="#FFE2B0" />
    <path d="M74.044 59.9968H121.267C123.2 59.9968 124.767 61.5638 124.767 63.4968V110.72H77.544C75.611 110.72 74.044 109.153 74.044 107.22V59.9968Z" fill="white" stroke="#D4682B" />
    <rect x="84.1982" y="70.0767" width="24.5868" height="24.4148" rx="12" stroke="#FF9051" strokeWidth="2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M103.465 92.9583L102.442 93.9786L110.455 101.949C111.077 102.566 112.085 102.569 112.708 101.953L116.29 98.4132C116.912 97.7974 116.914 96.798 116.293 96.1806L108.253 88.1838L107.265 89.1691C106.267 90.6724 104.972 91.963 103.465 92.9583Z" fill="#FF9051" />
</svg>