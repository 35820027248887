import React from "react"
import { MarketingDashSVG } from "../MarketingDashSVG"
import { MarketingTitle } from "../ReusableStyledComponents/MarketingMainTitle"
import { MediumText } from "../Typography"
import { VerticalSpacing } from "../VerticalSpacing"
import {
    DesktopImage,
    DesktopImageWrapper,
    DesktopImgSizer,
    DesktopImgSizerWrapper,
    DesktopScrollIndicator,
    DesktopSVG,
    HeaderTextSVGContainer,
    HeaderTextSVGWrapper,
    MarketingTextSVGDescription,
    MobileScrollIndicator,
    MobileSVG
} from "./MarketingTextSVGHeader.styles"

type MarketingTextSVGHeaderProps = {
    title?: JSX.Element,
    description?: JSX.Element,
    desktopSVG: JSX.Element,
    desktopImage?: string,
    desktopImageWidth?: number,
    desktopImageHeight?: number,
    desktopImageMarginTop?: number,
    mobileSVG: JSX.Element,
    showScrollIndicator?: boolean,
    subtitle?: string,
    textMaxWidth: number,
    textMarginTop?: number,
    containerMaxWidth: number,
    descriptionTextMaxWidth?: number,
    floatingComponent?: JSX.Element,
    mobileMaxWidth?: number,
    customTitleMarginBottom?: number,
}

export const MarketingTextSVGHeader = ({
    title,
    description,
    desktopSVG,
    desktopImage,
    desktopImageWidth,
    desktopImageHeight,
    desktopImageMarginTop,
    mobileSVG,
    showScrollIndicator = false,
    textMaxWidth,
    textMarginTop,
    containerMaxWidth,
    descriptionTextMaxWidth,
    subtitle,
    floatingComponent,
    mobileMaxWidth,
    customTitleMarginBottom,
}: MarketingTextSVGHeaderProps) => {
    const hasSubtitle = !!subtitle?.length;
    const hasDesktopImage = !!desktopImage?.length;
    const resizeFactor = 0.8;

    return <HeaderTextSVGWrapper containerMaxWidth={containerMaxWidth} hasSubtitle={hasSubtitle}>
        <DesktopImageWrapper >
            <DesktopSVG width={containerMaxWidth}>{desktopSVG}</DesktopSVG>
            {hasDesktopImage && desktopImageWidth && desktopImageHeight && <VerticalSpacing top={desktopImageMarginTop || 0}>
                <DesktopImgSizerWrapper width={desktopImageWidth * resizeFactor}>
                    <DesktopImgSizer
                        width={desktopImageWidth * resizeFactor}
                        height={desktopImageHeight * resizeFactor}
                    >
                        <DesktopImage desktopImageMaxWidth={desktopImageWidth * resizeFactor} src={desktopImage} />
                    </DesktopImgSizer>
                </DesktopImgSizerWrapper>
            </VerticalSpacing>}
        </DesktopImageWrapper>
        <HeaderTextSVGContainer
            textMaxWidth={textMaxWidth}
            descriptionTextMaxWidth={descriptionTextMaxWidth}
            marginTop={textMarginTop}
            customTitleMarginBottom={customTitleMarginBottom}
        >
            {
                hasSubtitle && <>
                    <MediumText>{subtitle}</MediumText>
                    <MarketingDashSVG />
                </>
            }
            {!!title && <MarketingTitle as="h1">{title}</MarketingTitle>}
            {description && <MarketingTextSVGDescription >{description}</MarketingTextSVGDescription>}
        </HeaderTextSVGContainer>
        {floatingComponent}
        <MobileSVG maxWidth={mobileMaxWidth}>{mobileSVG}</MobileSVG>
        {
            showScrollIndicator && <>
                <DesktopScrollIndicator width="17" height="104" viewBox="0 0 17 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="8.5" y1="2.18557e-08" x2="8.5" y2="104" stroke="#2E77FF" />
                    <circle cx="8.5" cy="23.5" r="7.5" stroke="#2E77FF" />
                </DesktopScrollIndicator>
                <MobileScrollIndicator width="17" height="64" viewBox="0 0 17 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 0L8.5 64" stroke="#2E77FF" />
                    <circle cx="8.5" cy="17.5" r="7.5" stroke="#2E77FF" />
                </MobileScrollIndicator>
            </>
        }
    </HeaderTextSVGWrapper >
}