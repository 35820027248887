export const PresentContentSVGDesktop = () => <svg width="1438" height="370" viewBox="0 0 1438 370" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1296.19 45.3765C1296.19 43.4435 1297.76 41.8765 1299.69 41.8765H1434C1435.93 41.8765 1437.5 43.4435 1437.5 45.3765V99.8706C1437.5 101.804 1435.93 103.371 1434 103.371H1296.19V45.3765Z" stroke="#E4EDFF" />
    <path d="M1229.94 107.871C1229.94 105.938 1231.5 104.371 1233.44 104.371H1295.19V166.129C1295.19 168.062 1293.63 169.629 1291.69 169.629H1233.44C1231.5 169.629 1229.94 168.062 1229.94 166.129V107.871Z" stroke="#FFBF73" />
    <path d="M1337.86 214.788C1337.86 212.579 1339.65 210.788 1341.86 210.788H1360.21C1362.42 210.788 1364.21 212.579 1364.21 214.788V233.141C1364.21 235.35 1362.42 237.141 1360.21 237.141H1337.86V214.788Z" fill="#82AEFF" />
    <path d="M1186.75 72.5C1184.82 72.5 1183.25 70.933 1183.25 69L1183.25 41.1235L1211.13 41.1235C1213.06 41.1235 1214.63 42.6905 1214.63 44.6235L1214.63 69C1214.63 70.933 1213.06 72.5 1211.13 72.5L1186.75 72.5Z" stroke="#E4EDFF" />
    <path d="M1118 13C1118 10.7909 1119.79 9 1122 9L1178.75 9C1180.96 9 1182.75 10.7909 1182.75 13V41.3765L1122 41.3765C1119.79 41.3765 1118 39.5856 1118 37.3765V13Z" fill="#E4EDFF" />
    <path d="M1263.99 115.048L1246.33 122.016C1238.37 125.158 1234.47 134.156 1237.61 142.114L1238.92 145.445C1239.21 146.171 1240.03 146.527 1240.76 146.24L1250.57 142.366C1252.77 141.497 1255.36 142.435 1256.3 144.61C1257.26 146.859 1256.17 149.446 1253.91 150.339L1245.35 153.718C1244.38 154.102 1244.14 155.386 1244.92 156.08C1249.14 159.829 1255.28 161.124 1260.89 158.908L1278.55 151.939C1286.51 148.798 1290.41 139.8 1287.27 131.841L1284.08 123.771C1280.94 115.812 1271.95 111.906 1263.99 115.048ZM1264.86 144.597C1263.32 145.206 1261.58 144.449 1260.97 142.909C1260.36 141.368 1261.12 139.626 1262.66 139.019C1264.2 138.41 1265.94 139.167 1266.55 140.707C1267.16 142.248 1266.4 143.99 1264.86 144.597Z" fill="#FFE2B0" />
    <path d="M1246.95 137.836C1249.09 137.836 1250.82 136.105 1250.82 133.969C1250.82 131.833 1249.09 130.102 1246.95 130.102C1244.82 130.102 1243.09 131.833 1243.09 133.969C1243.09 136.105 1244.82 137.836 1246.95 137.836Z" fill="#82AEFF" />
    <path d="M1257.35 129.681C1259.48 129.681 1261.21 127.95 1261.21 125.814C1261.21 123.678 1259.48 121.947 1257.35 121.947C1255.21 121.947 1253.48 123.678 1253.48 125.814C1253.48 127.95 1255.21 129.681 1257.35 129.681Z" fill="#FF9051" />
    <path d="M1270.86 126.694C1273 126.694 1274.73 124.962 1274.73 122.826C1274.73 120.691 1273 118.959 1270.86 118.959C1268.72 118.959 1266.99 120.691 1266.99 122.826C1266.99 124.962 1268.72 126.694 1270.86 126.694Z" fill="#FFA858" />
    <path d="M1278.78 160.48H1275.83C1275.32 160.48 1274.91 159.951 1274.91 159.298V138.127C1274.91 137.864 1274.96 137.605 1275.07 137.38L1276.75 133.842C1277.01 133.313 1277.61 133.313 1277.86 133.842L1279.54 137.38C1279.65 137.605 1279.71 137.864 1279.71 138.127V159.298C1279.71 159.951 1279.29 160.48 1278.78 160.48Z" fill="#FF9051" stroke="#FF9051" strokeMiterlimit="10" />
    <path d="M1281.71 134.249C1281.71 136.682 1279.74 138.655 1277.31 138.655C1274.87 138.655 1272.9 136.682 1272.9 134.249C1272.9 131.816 1274.87 125.864 1277.31 125.864C1279.74 125.864 1281.71 131.816 1281.71 134.249Z" fill="#D4682B" stroke="#D4682B" strokeMiterlimit="10" />
    <path d="M70.853 161.329H168.451V204.511C168.451 206.444 166.884 208.011 164.951 208.011H74.353C72.42 208.011 70.853 206.444 70.853 204.511V161.329Z" stroke="#E4EDFF" />
    <path d="M69.853 160.329L8.08187 160.329C6.14887 160.329 4.58186 158.762 4.58186 156.829L4.58187 112.297C4.58187 110.364 6.14888 108.797 8.08187 108.797L69.853 108.797L69.853 160.329Z" stroke="#E4EDFF" />
    <path d="M311.5 48.532C311.5 50.465 309.933 52.032 308 52.032L259.427 52.032C257.494 52.032 255.927 50.465 255.927 48.532L255.927 4.00001C255.927 2.06701 257.494 0.500007 259.427 0.500007L308 0.500011C309.933 0.500011 311.5 2.06702 311.5 4.00001L311.5 48.532Z" stroke="#E4EDFF" />
    <path d="M122.885 270.701C122.885 268.492 124.676 266.701 126.885 266.701H153.637C155.846 266.701 157.637 268.492 157.637 270.701V297.453C157.637 299.662 155.846 301.453 153.637 301.453H122.885V270.701Z" fill="#FFD08A" />
    <path d="M69.5447 78.353C69.5447 76.1439 71.3356 74.353 73.5447 74.353H104.297V105.105C104.297 107.314 102.506 109.105 100.297 109.105H69.5447V78.353Z" fill="#E4EDFF" />
    <path d="M182.394 99.2151L176.014 92.8857L170.449 99.9229C169.479 101.148 168.951 102.665 168.951 104.228V160.79H225.346C227.011 160.79 228.621 160.192 229.881 159.104L236.771 153.155L230.526 146.961V101.814C230.526 100.379 229.362 99.2151 227.927 99.2151H182.394Z" fill="#D2E2FF" />
    <path d="M178.257 153.708H233.846C235.499 153.708 236.839 152.368 236.839 150.715V95.126C236.839 93.4731 235.499 92.1331 233.846 92.1331H178.257C176.604 92.1331 175.264 93.4731 175.264 95.126V150.715C175.264 152.368 176.604 153.708 178.257 153.708Z" fill="#2E77FF" stroke="#2E77FF" strokeMiterlimit="10" />
    <path d="M193.394 107.413L189.363 111.774C188.662 112.533 188.721 113.669 189.493 114.312L214.558 135.181C214.87 135.441 215.254 135.612 215.668 135.675L222.159 136.67C223.13 136.82 223.955 135.927 223.673 135.032L221.788 129.055C221.668 128.673 221.439 128.335 221.127 128.074L196.062 107.206C195.29 106.562 194.094 106.655 193.394 107.413Z" fill="#183D82" stroke="#183D82" strokeMiterlimit="10" />
</svg>
