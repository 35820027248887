import styled from "styled-components";
import { media } from "../../../theme/theme";

export const PRESENT_SLIDER_MOBILE_BR = 500;

export const PresentSliderWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    min-height: 260px;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 60px;
        height: 100%;
        background:  linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        display: none;
        z-index: 2;
        transform: rotate(180deg);

        ${media.custom(PRESENT_SLIDER_MOBILE_BR)} {
            display: block;
        }
    }

    &:after{
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 60px;
        height: 100%;
        background:  linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        display: none;
        z-index: 2;

        ${media.custom(PRESENT_SLIDER_MOBILE_BR)} {
            display: block;
        }
    }
`;

export const PresentSliderArrowWrapper = styled.div`
    display: block;

    ${media.custom(PRESENT_SLIDER_MOBILE_BR)} {
        padding: 0 45px;
        position: relative;
    }
`;

export const PresentSliderContainer = styled.div`
    width: 100%;
    position: static;
    left: initial;
    transform: none;

    .swiper {
        padding: 20px 5px;

        .swiper-wrapper {
            transition-timing-function: linear;

            ${media.custom(PRESENT_SLIDER_MOBILE_BR)} {
                transition-timing-function: inherit;
            }

            > div {
                transition: transform .9s ease;

                ${media.custom(PRESENT_SLIDER_MOBILE_BR)} {
                    transform: scale(0.88);

                    &.swiper-slide-active, &.swiper-slide-duplicate-active {
                        transform: scale(0.98);
                    }
                }
            }
        }        
    }

    .swiper-slide{
        backface-visibility: hidden;
        transform: translate3d(0,0,0);
    }

    .swiper-wrapper{
        transform-style: preserve-3d;
    }
`;

export const PresentImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));

    &:hover {
        ${media.desktop} {
            cursor: pointer;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
        }
    }
`;

export const PresentArrow = styled.div<{ left?: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${({ left }) => left ? "0" : undefined};
    right: ${({ left }) => !left ? "0" : undefined};
    z-index: 3;
    cursor: pointer;
    display: none;

    ${media.custom(PRESENT_SLIDER_MOBILE_BR)} {
        display: block;
    }
`;