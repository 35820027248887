import { MarketingTextSVGCTAWrapper } from "./MarketingTextSVGHeader.styles";
import Link from "next/link";
import { APPLICATION_ROUTES } from "../../types/routes";
import { MarketingCTA, CTASize } from "../MarketingCTA";
import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink";
import { ShowDesktop, ShowMobile } from "../MarketingGetStarted/MarketingGetStarted.styles";

interface MarketingTextSVGCTAProps {
    text: string,
}

export const MarketingTextSVGCTA = ({ text }: MarketingTextSVGCTAProps) => {
    return <MarketingTextSVGCTAWrapper>
        <Link href={APPLICATION_ROUTES.SIGNUP} passHref legacyBehavior>
            <UnstyledLink >
                <ShowDesktop>
                    <MarketingCTA
                        text={text}
                        size={CTASize.L}
                    />
                </ShowDesktop>
                <ShowMobile>
                    <MarketingCTA
                        text={text}
                        size={CTASize.M}
                    />
                </ShowMobile>
            </UnstyledLink>
        </Link>
    </MarketingTextSVGCTAWrapper>
}