export const ProtectContentSVGDesktop = () => <svg width="1420" height="214" viewBox="0 0 1420 214" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1302.7 35.146H1416C1417.93 35.146 1419.5 36.713 1419.5 38.646V116.876C1419.5 118.809 1417.93 120.376 1416 120.376H1306.2C1304.27 120.376 1302.7 118.809 1302.7 116.876V35.146Z" stroke="#E9F1FF" />
    <path d="M1250 172.96H1345.94C1347.87 172.96 1349.44 174.527 1349.44 176.46V209.266C1349.44 211.199 1347.87 212.766 1345.94 212.766H1250C1248.07 212.766 1246.5 211.199 1246.5 209.266V176.46C1246.5 174.527 1248.07 172.96 1250 172.96Z" stroke="#FFBF73" />
    <path d="M1267.56 4C1267.56 1.79086 1269.35 0 1271.56 0H1298.2C1300.41 0 1302.2 1.79086 1302.2 4V34.646H1271.56C1269.35 34.646 1267.56 32.8552 1267.56 30.646V4Z" fill="#E9F1FF" />
    <path d="M111.28 118.988H156.92C158.853 118.988 160.42 120.555 160.42 122.488V171.628H111.28C109.347 171.628 107.78 170.061 107.78 168.128V122.488C107.78 120.555 109.347 118.988 111.28 118.988Z" stroke="#ACC9FF" />
    <path d="M164.92 171.827H265C266.933 171.827 268.5 173.394 268.5 175.327V209.759C268.5 211.692 266.933 213.259 265 213.259H161.42V175.327C161.42 173.394 162.987 171.827 164.92 171.827Z" stroke="#E9F1FF" />
    <path d="M224.967 56.0387C224.967 53.8296 226.758 52.0387 228.967 52.0387H253.792C256.001 52.0387 257.792 53.8296 257.792 56.0387V80.8631C257.792 83.0722 256.001 84.8631 253.792 84.8631H228.967C226.758 84.8631 224.967 83.0722 224.967 80.8631V56.0387Z" fill="#2E77FF" />
    <rect x="0.5" y="0.5" width="94.2708" height="67.0506" stroke="#E9F1FF" />
</svg>

