import styled from "styled-components";
import { media } from "../../theme/theme";
import { DashSVG } from "../MarketingDashSVG";
import { MarketingImageContainer, MarketingImageMobile } from "../ReusableStyledComponents/MarketingImageMobile";
import { MarketingSpacing } from "../ReusableStyledComponents/MarketingSpacing";
import { Body, H1, MediumText } from "../Typography";

export const HeaderTextSVGWrapper = styled.div<{ containerMaxWidth: number, hasSubtitle: boolean }>`
    max-width: ${({ containerMaxWidth }) => `${containerMaxWidth}px`};
    margin: 0 auto;
    width: 100%;
    position: relative;

    ${media.tablet} {
       margin: 0 auto 105px;
    }
`;

export const DesktopImageWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    display: none;

    ${media.tablet} {
        display: block;
    }
`;

export const DesktopSVG = styled.div<{ width: number }>`
    svg {
        width: ${({ width }) => `${width}px`};
        height: auto;
        margin-left: ${({ width }) => `calc((100vw - ${width}px) / 2)`};

        ${({ width }) => media.custom(width)} {
            margin: 0 auto;
        }
    }
`;

export const DesktopImgSizerWrapper = styled.div<{ width?: number, height?: number }>`
    display: block;
    position: relative;
    margin: 0 auto 0;
    width: 100%;
    max-width: ${({ width }) => width ? `${width}px` : null};
    padding-bottom: 45px;
    top: 20px;
`;

export const DesktopImgSizer = styled.div<{ width?: number, height?: number }>`
    overflow: hidden;
    height: 0;
    width: 100%;
    padding-top: ${({ width, height }) => width && height ? `calc(${height} / ${width} * 100%);` : null};  
`;

export const DesktopImage = styled.img<{ desktopImageMaxWidth?: number }>`
    display: block;
    max-width: ${({ desktopImageMaxWidth }) => desktopImageMaxWidth ? `calc(${desktopImageMaxWidth}px)` : null};
    margin: 0 auto;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const MobileSVG = styled.div<{ maxWidth?: number }>`
    ${MarketingImageContainer}
    
    ${media.tablet} {
        display: none;
    }

    ${MarketingImageMobile}
`;

export const HeaderTextSVGContainer = styled.div<{ textMaxWidth: number, descriptionTextMaxWidth?: number, marginTop?: number, customTitleMarginBottom?: number, }>`
    text-align: center;
    margin: 0 auto;
    ${MarketingSpacing}

    ${media.tablet} {
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: ${({ textMaxWidth }) => `${textMaxWidth}px`};
        width: 100%;
        margin-top: ${({ marginTop }) => marginTop ? `${marginTop}px` : undefined};
    }

    ${H1} {
        margin-bottom: ${({ customTitleMarginBottom }) => customTitleMarginBottom ? `${customTitleMarginBottom}px` : "30px"};

        ${media.tablet} {
            margin-bottom: ${({ customTitleMarginBottom }) => customTitleMarginBottom ? `${customTitleMarginBottom}px` : "40px"};
        }
    }

    ${Body} {
        color:  ${({ theme, color }) => color ? color : `${theme.marketing.colors.blackPearl80}`};
        margin: 0 auto 45px;
        max-width: ${({ descriptionTextMaxWidth }) => descriptionTextMaxWidth ? `${descriptionTextMaxWidth}px` : null};

        ${media.tablet} {
            margin: 0 auto;
        }
    }

    ${MediumText} {
        color:  ${({ theme, color }) => color ? color : `${theme.marketing.colors.dodgerBlue100}`};
        margin-bottom: 10px;
    }

    ${DashSVG} {
        position: static;
        top: initial;
        left: initial;
        transform: none;
        margin-bottom: 0;
    }
`;

export const DesktopScrollIndicator = styled.svg`
    display: none;

    ${media.tablet} {
        position: absolute;
        top: 100%;
        left: 50%;
        display: block;
        transform: translateX(-50%);
    }
`;

export const MobileScrollIndicator = styled.svg`
    width: 17px;
    display: block;
    margin: 0 auto;

    ${media.tablet} {
        display: none;
    }
`


export const MarketingTextSVGDescription = styled(Body)`
    line-height: 32px;
`;

export const MarketingTextSVGCTAWrapper = styled.div<{ topMargin?: number }>`
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
    margin-top: 45px;
    ${MarketingSpacing};
`;