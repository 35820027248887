export const PartnerDesktopSVG = () => <svg width="1320" height="416" viewBox="0 0 1320 416" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56 196C56 193.791 57.7909 192 60 192H120V252C120 254.209 118.209 256 116 256H60C57.7909 256 56 254.209 56 252L56 196Z" fill="#E4EDFF" />
    <path d="M120.5 48.5H183.5V111.5H120.5V48.5Z" stroke="#E4EDFF" />
    <path d="M73 4C73 1.79086 74.7909 0 77 0H117C119.209 0 121 1.79086 121 4V48H77C74.7909 48 73 46.2091 73 44V4Z" fill="#FFD08A" />
    <path d="M119.5 191.5L0.499992 191.5L0.499999 116C0.499999 114.067 2.067 112.5 4 112.5L119.5 112.5L119.5 191.5Z" stroke="#E4EDFF" />
    <path d="M188 0.5L228 0.5C229.933 0.5 231.5 2.067 231.5 4V44C231.5 45.933 229.933 47.5 228 47.5L184.5 47.5V4C184.5 2.067 186.067 0.5 188 0.5Z" stroke="#E4EDFF" />
    <path d="M206.087 140.344C206.045 139.917 206.023 139.629 206.023 139.48C206.023 138.477 206.237 137.592 206.663 136.824C207.09 136.035 207.698 135.171 208.487 134.232C209.106 133.485 209.575 132.845 209.895 132.312C210.215 131.779 210.375 131.213 210.375 130.616C210.375 129.784 210.13 129.112 209.639 128.6C209.149 128.088 208.455 127.832 207.559 127.832C206.301 127.832 205.149 128.408 204.103 129.56L202.279 127.896C203.901 126.083 205.789 125.176 207.943 125.176C209.629 125.176 210.973 125.635 211.975 126.552C212.999 127.469 213.511 128.749 213.511 130.392C213.511 131.267 213.319 132.045 212.935 132.728C212.551 133.411 211.975 134.211 211.207 135.128C210.439 136.003 209.863 136.781 209.479 137.464C209.095 138.147 208.903 138.915 208.903 139.768C208.903 140.024 208.914 140.216 208.935 140.344H206.087ZM207.559 147.384C206.941 147.384 206.418 147.171 205.991 146.744C205.586 146.317 205.383 145.773 205.383 145.112C205.383 144.451 205.586 143.907 205.991 143.48C206.418 143.032 206.941 142.808 207.559 142.808C208.178 142.808 208.69 143.032 209.095 143.48C209.522 143.907 209.735 144.451 209.735 145.112C209.735 145.773 209.522 146.317 209.095 146.744C208.69 147.171 208.178 147.384 207.559 147.384Z" fill="white" />
    <path d="M1208 240H1256V284C1256 286.209 1254.21 288 1252 288H1212C1209.79 288 1208 286.209 1208 284V240Z" fill="#FFA858" />
    <path d="M1256.5 136.5H1319.5V236C1319.5 237.933 1317.93 239.5 1316 239.5H1256.5V136.5Z" stroke="#E4EDFF" />
    <path d="M1160.5 196C1160.5 194.067 1162.07 192.5 1164 192.5H1204C1205.93 192.5 1207.5 194.067 1207.5 196V239.5H1164C1162.07 239.5 1160.5 237.933 1160.5 236V196Z" stroke="#E4EDFF" />
    <path d="M1120 4C1120 1.79086 1121.79 0 1124 0H1180C1182.21 0 1184 1.79086 1184 4V64H1124C1121.79 64 1120 62.2091 1120 60V4Z" fill="#ACC9FF" />
    <path d="M1184 64H1244.13C1245.31 64 1246.44 64.5273 1247.2 65.4393L1255.07 74.8875C1255.67 75.6063 1256 76.5125 1256 77.4482V136H1197.45C1196.51 136 1195.61 135.672 1194.89 135.073L1185.44 127.199C1184.53 126.439 1184 125.314 1184 124.126V64Z" fill="#D2E2FF" />
    <path d="M1184 64H1244C1246.21 64 1248 65.7909 1248 68V128H1188C1185.79 128 1184 126.209 1184 124V64Z" fill="#5892FF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1234.49 87.618L1224.38 77.5121C1224.05 77.1843 1223.61 77 1223.15 77H1208.85C1208.39 77 1207.95 77.1843 1207.62 77.5121L1197.51 87.618C1197.18 87.9458 1197 88.3905 1197 88.8538V103.146C1197 103.61 1197.18 104.054 1197.51 104.382L1207.62 114.488C1207.95 114.816 1208.39 115 1208.85 115H1223.15C1223.61 115 1224.05 114.816 1224.38 114.488L1234.49 104.382C1234.82 104.054 1235 103.61 1235 103.146V88.8538C1235 88.3905 1234.82 87.9458 1234.49 87.618ZM1209 96C1209 99.866 1212.13 103 1216 103C1219.87 103 1223 99.866 1223 96C1223 92.134 1219.87 89 1216 89C1212.13 89 1209 92.134 1209 96Z" fill="#183D82" />
</svg>
