import styled from "styled-components";
import { media } from "../../theme/theme";


export const MarketingCarouselMainWrapper = styled.div<{ maxWidth?: number }>`
    max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : null};
    margin: 40px auto 0;
    width: 100%;
    position: relative;
`;

export const MarketingCarouselSlidesWrapper = styled.div<{ usesFixedEl: boolean, contentMarginTop: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.tablet} {
        margin-top: ${({ contentMarginTop }) => `${contentMarginTop}px`}; 
    }

    ${media.custom(1120)} {
        align-items:  ${({ usesFixedEl }) => usesFixedEl ? `flex-start` : null};
    }
    
    > div {
        &:first-child {
            display: none;

            ${media.tablet} {
                display: block;
            }
        }

        &:nth-child(2) {
            width: 100%;
            margin-left: auto;

            ${media.tablet} {
                max-width: ${({ usesFixedEl }) => usesFixedEl ? `730px` : null};
                width: ${({ usesFixedEl }) => usesFixedEl ? `calc(100% - 340px)` : null};
            }

            ${media.custom(1024)} {
                width: ${({ usesFixedEl }) => usesFixedEl ? `calc(100% - 390px)` : null};
            }
        }
    }
`;

export const NavigationWrapper = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            margin-right: 8px;
        }
        
        &:nth-child(2) {
            margin-top: 6px;

            ${media.tablet} {
                margin-top: 7px;
            }
        }
    }
`;

export const FixedElMobile = styled.div`
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;

    ${media.tablet} {
        display: none;
    }

    > div {
        margin: 0 auto;
        width: calc(100% - 32px);
        max-width: 550px;
        text-align: center;
        padding: 30px 15px;
        display: block;
        ${media.phone} {
            width: calc(100% - 80px);
        }
    }
`;

export const MarketingCarouselArrowsWrapper = styled.div`
    display: none;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 0;
    width: 100%;
    z-index: 2;

    ${media.custom(900)} {
        display: flex;
    }
`;

export const MarketingCarouselArrowSvg = styled.svg<{ canTriggerAction?: boolean }>`
    opacity: ${({ canTriggerAction }) => canTriggerAction ? `0.7` : null};
    cursor: ${({ canTriggerAction }) => canTriggerAction ? `pointer` : null};

    &:hover {
        opacity: 1;
    }

    path {
        fill: ${({ canTriggerAction, theme }) => canTriggerAction ? theme.marketing.colors.dodgerBlue100 : theme.marketing.colors.blackPearl70};
    }
`;