import { css } from "styled-components";
import { media } from "../../theme/theme";

export const MarketingImageContainer = css<{ maxWidth?: number }>`
   display: block;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
`;

export const MarketingImageMobile = css<{ maxWidth?: number }>`
    > img {
        width: ${({ maxWidth }) => maxWidth ? `${0.8 * maxWidth}px` : undefined};
        margin: 0 auto;
        display: block;
        margin-left: ${({ maxWidth }) => maxWidth ? `calc((100vw - ${0.8 * maxWidth}px) / 2)` : undefined};
        position: relative;
        height: auto;

        ${({ maxWidth }) => media.custom(maxWidth ? maxWidth : 0)} {
            margin: 0 auto;
        }

        ${media.phone} {
            width: 100%;
        }
    }
`;