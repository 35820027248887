export const PricingDesktop = ({ hideElements = false }) => <svg width="1320" height="289" viewBox="0 0 1320 289" fill="none" xmlns="http://www.w3.org/2000/svg">
    {!hideElements && <>
        <path d="M1208 241H1256V285C1256 287.209 1254.21 289 1252 289H1212C1209.79 289 1208 287.209 1208 285V241Z" fill="#FFA858" />
        <path d="M1256.5 137.5H1319.5V237C1319.5 238.933 1317.93 240.5 1316 240.5H1256.5V137.5Z" stroke="#E4EDFF" />
        <path d="M1160.5 197C1160.5 195.067 1162.07 193.5 1164 193.5H1204C1205.93 193.5 1207.5 195.067 1207.5 197V240.5H1164C1162.07 240.5 1160.5 238.933 1160.5 237V197Z" stroke="#E4EDFF" />
    </>}
    <path d="M1105 69C1105 66.7909 1106.79 65 1109 65H1137V93C1137 95.2091 1135.21 97 1133 97H1105V69Z" fill="#FFE2B0" />
    <path d="M1185 17L1185 65L1137 65L1137 21C1137 18.7909 1138.79 17 1141 17L1185 17Z" fill="#E4EDFF" />
    <path d="M1184 65H1244.13C1245.31 65 1246.44 65.5273 1247.2 66.4393L1255.07 75.8875C1255.67 76.6063 1256 77.5125 1256 78.4482V137H1197.45C1196.51 137 1195.61 136.672 1194.89 136.073L1185.44 128.199C1184.53 127.439 1184 126.314 1184 125.126V65Z" fill="#D2E2FF" />
    <path d="M1184 65H1244C1246.21 65 1248 66.7909 1248 69V129H1188C1185.79 129 1184 127.209 1184 125V65Z" fill="#5892FF" />
    {!hideElements && <path d="M75 281C72.7909 281 71 279.209 71 277L71 237C71 234.791 72.7909 233 75 233L119 233L119 277C119 279.209 117.209 281 115 281L75 281Z" fill="#82AEFF" />}
    <path d="M119.5 192.5L0.499992 192.5L0.499999 117C0.499999 115.067 2.06701 113.5 4 113.5L119.5 113.5L119.5 192.5Z" stroke="#E4EDFF" />
    <path d="M192.5 4C192.5 2.067 194.067 0.5 196 0.5L228 0.5C229.933 0.5 231.5 2.067 231.5 4V36C231.5 37.933 229.933 39.5 228 39.5L192.5 39.5V4Z" stroke="#E4EDFF" />
    <path d="M192 40H131.874C130.686 40 129.561 40.5273 128.801 41.4393L120.927 50.8875C120.328 51.6063 120 52.5125 120 53.4482V112H178.552C179.488 112 180.394 111.672 181.113 111.073L190.561 103.199C191.473 102.439 192 101.314 192 100.126V40Z" fill="#FFE2B0" />
    <path d="M191.5 40.5H132C130.067 40.5 128.5 42.067 128.5 44V103.5H188C189.933 103.5 191.5 101.933 191.5 100V40.5Z" fill="white" stroke="#D4682B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M159.672 81.731C159.967 81.5742 160.319 81.5742 160.614 81.731L169.315 86.371C170.048 86.7617 170.91 86.1407 170.772 85.3218L169.103 75.4537C169.049 75.1325 169.154 74.8049 169.386 74.5758L176.465 67.5768C177.051 66.9971 176.724 65.9966 175.908 65.8764L166.149 64.4382C165.822 64.39 165.54 64.1835 165.396 63.8864L161.042 54.9388C160.677 54.1889 159.609 54.1889 159.244 54.9388L154.89 63.8864C154.745 64.1835 154.464 64.39 154.137 64.4382L144.378 65.8764C143.562 65.9966 143.235 66.9971 143.821 67.5768L150.9 74.5758C151.132 74.8049 151.237 75.1325 151.183 75.4537L149.514 85.3218C149.376 86.1407 150.238 86.7617 150.971 86.371L159.672 81.731Z" fill="#FF9051" />
    {!hideElements && <>
        <path d="M182.5 197C182.5 195.067 180.933 193.5 179 193.5H119.5V253C119.5 254.933 121.067 256.5 123 256.5H182.5V197Z" stroke="#E4EDFF" strokeMiterlimit="10" />
        <path d="M142.782 204.287L137.528 207.514C136.614 208.076 136.328 209.272 136.888 210.188L155.055 239.865C155.281 240.234 155.604 240.534 155.99 240.733L162.027 243.848C162.93 244.314 164.005 243.654 164 242.635L163.966 235.832C163.964 235.398 163.843 234.974 163.617 234.604L145.45 204.927C144.89 204.012 143.696 203.726 142.782 204.287Z" fill="#E4EDFF" />
    </>}
</svg>

