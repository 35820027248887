export const GenericGridDesktop = () => <svg viewBox="0 0 1320 288" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56 196C56 193.791 57.7909 192 60 192H120V252C120 254.209 118.209 256 116 256H60C57.7909 256 56 254.209 56 252L56 196Z" fill="#E4EDFF" />
    <path d="M120.5 48.5H183.5V111.5H120.5V48.5Z" stroke="#E4EDFF" />
    <path d="M73 4C73 1.79086 74.7909 0 77 0H117C119.209 0 121 1.79086 121 4V48H77C74.7909 48 73 46.2091 73 44V4Z" fill="#FFD08A" />
    <path d="M119.5 191.5L0.499992 191.5L0.499999 116C0.499999 114.067 2.067 112.5 4 112.5L119.5 112.5L119.5 191.5Z" stroke="#E4EDFF" />
    <path d="M188 0.5L228 0.5C229.933 0.5 231.5 2.067 231.5 4V44C231.5 45.933 229.933 47.5 228 47.5L184.5 47.5V4C184.5 2.067 186.067 0.5 188 0.5Z" stroke="#E4EDFF" />
    <path d="M228 112C230.209 112 232 113.791 232 116L232 160L188 160C185.791 160 184 158.209 184 156L184 112L228 112Z" fill="#5892FF" />
    <path d="M1208 240H1256V284C1256 286.209 1254.21 288 1252 288H1212C1209.79 288 1208 286.209 1208 284V240Z" fill="#FFA858" />
    <path d="M1256.5 136.5H1319.5V236C1319.5 237.933 1317.93 239.5 1316 239.5H1256.5V136.5Z" stroke="#E4EDFF" />
    <path d="M1164 192.5H1204C1205.93 192.5 1207.5 194.067 1207.5 196V239.5H1164C1162.07 239.5 1160.5 237.933 1160.5 236V196C1160.5 194.067 1162.07 192.5 1164 192.5Z" stroke="#E4EDFF" />
    <path d="M1120 4C1120 1.79086 1121.79 0 1124 0H1180C1182.21 0 1184 1.79086 1184 4V64H1124C1121.79 64 1120 62.2091 1120 60V4Z" fill="#ACC9FF" />
    <path d="M1184 64H1244.13C1245.31 64 1246.44 64.5273 1247.2 65.4393L1255.07 74.8875C1255.67 75.6063 1256 76.5125 1256 77.4482V136H1197.45C1196.51 136 1195.61 135.672 1194.89 135.073L1185.44 127.199C1184.53 126.439 1184 125.314 1184 124.127V64Z" fill="#D2E2FF" />
    <path d="M1184 64H1244C1246.21 64 1248 65.7909 1248 68V128H1188C1185.79 128 1184 126.209 1184 124V64Z" fill="#5892FF" />
</svg>

