export const AnalyzeContentSVGDesktop = () => <svg width="1422" height="257" viewBox="0 0 1422 257" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.2529 48.2701H85.7363C86.9538 48.2701 88.1049 48.8246 88.8639 49.7765L94.7598 57.171C95.3246 57.8795 95.6322 58.7587 95.6322 59.6647V108.241H49.3138C48.3481 108.241 47.415 107.891 46.6867 107.257L39.6258 101.107C38.7536 100.348 38.2529 99.2476 38.2529 98.0909V48.2701Z" fill="#E4EDFF" />
    <path d="M38.2529 48.2701H85.2567C87.4659 48.2701 89.2567 50.061 89.2567 52.2701V101.577H42.2529C40.0438 101.577 38.2529 99.7865 38.2529 97.5774V48.2701Z" fill="#FFE2B0" />
    <path d="M72.0721 59.4963C72.0721 58.3918 72.9675 57.4963 74.0721 57.4963H78.9619C80.0664 57.4963 80.9619 58.3918 80.9619 59.4963V90.7238C80.9619 91.8284 80.0664 92.7238 78.9619 92.7238H74.0721C72.9675 92.7238 72.0721 91.8284 72.0721 90.7238V59.4963Z" stroke="#FF9051" strokeWidth="2" />
    <rect x="59.1416" y="67.5613" width="8.0816" height="25.1625" rx="2" fill="#FF9051" stroke="#FF9051" />
    <path d="M47.0192 79.6263C47.0192 78.5218 47.9146 77.6263 49.0192 77.6263H52.2926C53.3972 77.6263 54.2926 78.5218 54.2926 79.6263V90.7238C54.2926 91.8284 53.3972 92.7238 52.2926 92.7238H49.0192C47.9146 92.7238 47.0192 91.8284 47.0192 90.7238V79.6263Z" stroke="#FF9051" strokeWidth="2" />
    <path d="M4 8.78979H34.2529C36.1859 8.78979 37.7529 10.3568 37.7529 12.2898V47.7702H3.99999C2.067 47.7702 0.5 46.2032 0.5 44.2702V12.2898C0.5 10.3568 2.067 8.78979 4 8.78979Z" stroke="#E4EDFF" />
    <rect x="118.446" y="130.397" width="45.2222" height="47.3097" rx="3.5" stroke="#E4EDFF" />
    <path d="M175.341 1.29346H204C205.933 1.29346 207.5 2.86046 207.5 4.79346V35.108C207.5 37.041 205.933 38.608 204 38.608H175.341C173.408 38.608 171.841 37.041 171.841 35.108V4.79346C171.841 2.86046 173.408 1.29346 175.341 1.29346Z" stroke="#E4EDFF" />
    <rect x="1226.5" y="120.058" width="91.1376" height="54.3718" rx="3.5" stroke="#E4EDFF" />
    <rect x="1376.99" y="210.74" width="43.5332" height="45.5444" rx="3.5" stroke="#E4EDFF" />
    <path d="M1294.5 4.5H1321.82C1323.75 4.5 1325.32 6.067 1325.32 8V40.4145H1294.5C1292.56 40.4145 1291 38.8475 1291 36.9145V8C1291 6.067 1292.56 4.5 1294.5 4.5Z" stroke="#E4EDFF" />
    <path d="M1318.14 53.8905C1318.14 52.9678 1318.46 52.0734 1319.04 51.3589L1325.18 43.8513C1326.7 41.9927 1328.97 40.9146 1331.37 40.9146H1392.62V105.786C1392.62 107.914 1391.77 109.953 1390.26 111.454L1383.3 118.392C1382.55 119.139 1381.53 119.558 1380.47 119.558H1318.14V53.8905Z" fill="#D2E2FF" />
    <path d="M1325.82 44.9146C1325.82 42.7054 1327.61 40.9146 1329.82 40.9146H1392.95V107.309C1392.95 109.518 1391.16 111.309 1388.95 111.309H1325.82V44.9146Z" fill="#5892FF" />
    <rect width="38.3907" height="40.1245" rx="19.1953" transform="matrix(-1 0 0 1 1378.79 56.1619)" stroke="#183D82" strokeWidth="7" />
    <path d="M1358.76 56.1619V56.1619C1369.81 56.1619 1378.79 65.1784 1378.79 76.2241V76.2241" stroke="#D2E2FF" strokeWidth="7" />
</svg>

