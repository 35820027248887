export const DistributeContentSVGDesktop = () => <svg width="1440" height="309" viewBox="0 0 1440 309" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M96.5035 113.888H154.171C155.366 113.888 156.499 114.422 157.259 115.345L164.752 124.441C165.342 125.157 165.664 126.056 165.664 126.984V183.853H109.491C108.548 183.853 107.635 183.52 106.913 182.911L97.9253 175.334C97.0238 174.574 96.5035 173.455 96.5035 172.276V113.888Z" fill="#E4EDFF" />
    <path d="M96.5035 113.888H153.98C156.189 113.888 157.98 115.679 157.98 117.888V176.079H100.503C98.2943 176.079 96.5035 174.288 96.5035 172.079V113.888Z" fill="#FFE2B0" />
    <path d="M116.508 163.145C120.005 163.145 122.841 160.31 122.841 156.812C122.841 153.314 120.005 150.479 116.508 150.479C113.01 150.479 110.175 153.314 110.175 156.812C110.175 160.31 113.01 163.145 116.508 163.145Z" fill="#FF9051" />
    <path d="M110.175 144.146C115.214 144.146 120.046 146.148 123.609 149.711C127.172 153.274 129.174 158.106 129.174 163.145H135.507C135.507 156.427 132.838 149.983 128.087 145.233C123.337 140.482 116.893 137.813 110.175 137.813V144.146Z" fill="#FF9051" />
    <path d="M110.175 131.48C114.333 131.48 118.451 132.299 122.293 133.89C126.134 135.482 129.625 137.814 132.565 140.754C135.506 143.695 137.838 147.186 139.43 151.027C141.021 154.869 141.84 158.987 141.84 163.145H148.173C148.173 153.067 144.17 143.402 137.044 136.276C129.918 129.15 120.253 125.147 110.175 125.147V131.48Z" fill="#FF9051" />
    <path d="M100.503 2.60486H202.678C204.611 2.60486 206.178 4.17186 206.178 6.10486V61.6363C206.178 63.5693 204.611 65.1363 202.678 65.1363H97.0035V6.10486C97.0035 4.17186 98.5705 2.60486 100.503 2.60486Z" stroke="#E4EDFF" />
    <rect x="0.5" y="64.528" width="95.5035" height="48.8601" stroke="#E4EDFF" />
    <rect x="191.094" y="267.99" width="38.4056" height="38.4056" rx="3.5" stroke="#E4EDFF" />
    <path d="M1246.07 106.652C1246.07 105.716 1246.4 104.81 1247 104.091L1253.02 96.8652C1253.78 95.9532 1254.91 95.4259 1256.1 95.4259H1300.74C1302.95 95.4259 1304.74 97.2168 1304.74 99.4259V144.071C1304.74 145.258 1304.21 146.384 1303.3 147.144L1296.08 153.165C1295.36 153.765 1294.45 154.093 1293.51 154.093H1246.07V106.652Z" fill="#D2E2FF" />
    <path d="M1252.59 99.4259C1252.59 97.2168 1254.38 95.4259 1256.59 95.4259H1304.74V143.574C1304.74 145.783 1302.95 147.574 1300.74 147.574H1252.59V99.4259Z" fill="#5892FF" />
    <rect x="1246.57" y="269.481" width="38.9259" height="38.9259" rx="3.5" stroke="#E4EDFF" />
    <path d="M1307.11 25.8518H1439.5V94.9259H1303.61V29.3518C1303.61 27.4188 1305.18 25.8518 1307.11 25.8518Z" stroke="#E4EDFF" />
    <path d="M1294.43 134.458H1261.27C1260.08 134.458 1259.11 133.474 1259.11 132.26V110.661C1259.11 109.447 1260.08 108.463 1261.27 108.463H1294.43C1295.63 108.463 1296.59 109.447 1296.59 110.661V132.26C1296.59 133.474 1295.63 134.458 1294.43 134.458Z" fill="#183D82" />
    <path d="M1263.28 114.749L1276.07 119.399C1277.22 119.819 1278.48 119.819 1279.63 119.399L1292.42 114.749" stroke="#2E77FF" strokeWidth="2" strokeMiterlimit="10" />
</svg>

