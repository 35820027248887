import styled from "styled-components";
import { media } from "../../theme/theme";

export const DashSVG = styled.svg`
    display: block;
    width: 1px;
    margin: 0 auto 30px;

    ${media.tablet} {
        margin-bottom: 45px;
    }
`;

export const MarketingDashSVG = () => <DashSVG width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="27" stroke="#2E77FF" shapeRendering="crispEdges" />
</DashSVG>