import styled from "styled-components";
import { media } from "../../theme/theme";
import { H1 } from "../Typography";

export const MARKETING_TITLE_BR = 980;

export const MarketingTitle = styled(H1)`
    color:  ${({ theme }) => theme ? `${theme.marketing.colors.blackPearl100}` : null};
    -webkit-font-smoothing: antialiased;
    font-size: 36px;
    line-height: 40px;

    ${media.custom(MARKETING_TITLE_BR)} {
        font-size: 52px;
        line-height: 64px;
    }

    strong {
        font-weight: ${({ theme }) => theme ? theme.typography.weight.semibold : null};
    }
`;

export const MarketingTitleBr = styled.br`
    display: none;

    ${media.tablet} {
        display: block;
    }
`;