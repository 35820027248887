import styled from "styled-components";
import { media } from "../../theme/theme";

export const GetStartedWrapper = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
`;

export const ShowMobile = styled.div`
    display: block;
    
    ${media.phone} {
        display: none;
    }
`;

export const ShowDesktop = styled.div`
    display: none;
    
    ${media.phone} {
        display: block;
    }
`;

export const DesktopSVG = styled.svg`
    height: auto;
    width: 1035px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    ${media.custom(1035)} {
        width: 100%;
        left: initial;
        transform: initial;
    }
`;

export const GetStartedText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    text-align: center;
    width: 100%;

    ${media.phone} {
        transform: translate(-50%, -50%);
    }
`;

export const ButtonWrapper = styled.div`
    margin-top: 45px;

    ${media.phone} {
        margin-top: 64px;
    }
`;
