import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from 'swiper';
import { PresentImage, PresentArrow, PresentSliderContainer, PresentSliderWrapper, PresentSliderArrowWrapper, PRESENT_SLIDER_MOBILE_BR } from "./PresentSlider.styles";
import { UnstyledLink } from "../../ReusableStyledComponents/UnstyledLink";
import { MarketingCarouselArrowSvg } from "../../MarketingCarousel/MarketingCarousel.styles";

const urls = [
    "http://authory.com/NickMoore",
    "http://authory.com/ErinRupp",
    "http://authory.com/digidave",
    "http://authory.com/IvanFernandez",
    "http://authory.com/AlKingsley",
    "http://authory.com/KaelyMonahan",
    "http://authory.com/ColleenWilliams",
    "http://authory.com/buster",
]

export const PresentSlider = () => {
    const [hidden, setHidden] = useState(true);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | undefined>(undefined);

    return <PresentSliderArrowWrapper>
        <PresentSliderWrapper>
            <PresentSliderContainer>
                <div style={{ opacity: hidden ? 0 : 1 }}>
                    <Swiper
                        loop
                        speed={20000}
                        slidesPerView={1}
                        spaceBetween={10}
                        modules={[Autoplay]}
                        autoplay={{ delay: 0, pauseOnMouseEnter: true, disableOnInteraction: false }}
                        onSwiper={(e) => {
                            setHidden(false);
                            setThumbsSwiper(e);
                        }}
                        breakpoints={{
                            [PRESENT_SLIDER_MOBILE_BR]: {
                                slidesPerView: 2,
                                centeredSlides: true,
                                spaceBetween: 0,
                                speed: 2400,
                                autoplay: {
                                    delay: 4000,
                                }
                            },
                        }}
                    >
                        {
                            urls.map((url, index) => <SwiperSlide key={index}>
                                <UnstyledLink target="_blank" href={`${url}?target=visitor`}>
                                    <PresentImage src={`/authory_static_files/images/features/present/slider/present_${index + 1}.png`} />
                                </UnstyledLink>
                            </SwiperSlide>)
                        }
                    </Swiper>
                </div>
            </PresentSliderContainer>
        </PresentSliderWrapper>
        <PresentArrow left onClick={() => { thumbsSwiper && thumbsSwiper.slidePrev() }}>
            <MarketingCarouselArrowSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.414 59.414" width="40px" canTriggerAction={false} >
                <path fill="#9CA1AA" d="M45.268 1.414 43.854 0 14.146 29.707l29.708 29.707L45.268 58 16.975 29.707z" />
            </MarketingCarouselArrowSvg>
        </PresentArrow>
        <PresentArrow onClick={() => { thumbsSwiper && thumbsSwiper.slideNext() }}>
            <MarketingCarouselArrowSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.255 47.255" width="40px" canTriggerAction={false} >
                <path fill="#9CA1AA" d="M12.314 47.255a.999.999 0 0 1-.707-1.707l21.92-21.92-21.92-21.92A.999.999 0 1 1 13.021.294L35.648 22.92a.999.999 0 0 1 0 1.414L13.021 46.962a.999.999 0 0 1-.707.293z" />
            </MarketingCarouselArrowSvg>
        </PresentArrow>
    </PresentSliderArrowWrapper>
}

export default PresentSlider;